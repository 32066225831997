import React from "react"

export default () => (
  <div
    style={{
      textAlign: "center",
      fontFamily: "helvetica",
      fontSize: 20,
      textShadow: "pink 2px  2px 10px",
    }}
  >
    Welcome to LudoPom!
  </div>
)
